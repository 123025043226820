import { setCookie as setNextCookie } from "cookies-next/server";
import { IncomingMessage, ServerResponse } from "http";
import Cookies from "js-cookie";

const cookiesEnabled = () => {
  return (
    typeof document !== "undefined" &&
    typeof navigator !== "undefined" &&
    navigator.cookieEnabled
  );
};

export const getCookie = (name: string): string | undefined => {
  if (cookiesEnabled()) {
    return Cookies.get(name);
  }
};

export const setCookie = (
  cookieName: string,
  value: string | boolean,
  domain: string,
  expires: number | Date = 365, // days
  path = "/",
): void => {
  if (cookiesEnabled()) {
    Cookies.set(cookieName, String(value), {
      domain,
      expires,
      path,
    });
  }
};

export const removeCookie = (
  name: string,
  domain: string,
  path = "/",
): void => {
  if (cookiesEnabled()) {
    Cookies.remove(name, { domain, path });
  }
};

interface OptionsType {
  req?: IncomingMessage;
  res?: ServerResponse;
  maxAge?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "lax" | "strict" | "none";
}

export const setServerSideCookie = async (
  key: string,
  data: string | object,
  options: OptionsType = {},
): Promise<void> => {
  const { req, res, ...restOptions } = options;
  const cookieValue = typeof data === "string" ? data : JSON.stringify(data);
  const ONE_YEAR_MS = 60 * 60 * 24 * 365 * 1000;
  const cookieOptions = {
    expires: new Date(Date.now() + ONE_YEAR_MS),
    maxAge: 60 * 60 * 24 * 365,
    path: "/",
    ...restOptions,
  };

  setNextCookie(key, cookieValue, { req, res, ...cookieOptions });
};
