/**
 * The set of modal components provided in this module offer a framework
 * for rendering modals in general, along with (eventually) a set of three
 * types of modals described here: https://leafly.atlassian.net/wiki/spaces/BOTANIC/pages/1579712629/Modals
 *
 * To install the modal framework in your application, the ModalFrame
 * component from this module should be installed once, either in the "App"
 * class if using next.js, or in a shared layout module used for every page
 * in the application. The ModalFrame mounts a React context that keeps some
 * internal state about visible modals, as well as adds a "portal" to the
 * DOM wherein the modal components used throughout the application will be
 * rendered. The frame needs to wrap as much of the page content as possible
 * as it is also responsible for hiding non-modal page content from screen
 * readers when modals are displayed.
 *
 * The modal component(s) require a few attributes that must be present to
 * meet accessibility standards, which are present in the example below.
 *
 * A minimal implementation would appear as shown below.
 *
 * In _app.js:
 *
 *   import { ModalFrame } from "components/botanic/Modal/ModalFrame";
 *
 *   class MyApp extends App {
 *     render() {
 *       const { Component, pageProps } = this.props;
 *
 *       return (
 *         <ModalFrame>
 *           <Component {...pageProps} />
 *         </ModalFrame>
 *       )
 *     }
 *   }
 *
 * Somewhere else inside of the application:
 *
 *   function ModalButton() {
 *     // The parent component of the modal should keep some state to track the
 *     // visibility of the modal. The `onDismissed` callback provided to the
 *     // modal must reset this state in order to remove the modal from the DOM
 *     // when it is dismissed by the user and all visual transitions have been
 *     // completed.
 *     const [isVisible, setIsVisible] = useState(false);
 *
 *     // Passed in to the modal as `returnFocusRef`, this should be a reference
 *     // to the button or link that triggered the visibility of the modal.
 *     const buttonRef = useRef();
 *
 *     return (
 *       <>
 *         <button ref={buttonRef} onClick={() => setIsVisible(true)}>
 *           Show Modal
 *         </button>
 *         {isVisible && (
 *           <InformationalModal
 *             onDismissed={() => setIsVisible(false)}
 *             returnFocusRef={buttonRef}
 *             title="Hello!"
 *           >
 *             (Add content here)
 *           </InformationalModal>
 *         )}
 *       </>
 *     );
 *   }
 *
 * When testing components that render a modal, use the ModalFrame component as
 * part of the setup for the test, adding the `__testing` prop to the frame
 * in order to enable a rendering mode that is more forgiving for the test
 * environment. This flag disabled some modal functionality that would otherwise
 * make accessibility testing more difficult.
 *
 *   <ModalFrame __testing>
 *     <InformationalModal ... />
 *   </ModalFrame>
 *
 */
export { ActionRequiredModal } from "./ActionRequiredModal";
export { DecisionPointModal } from "./DecisionPointModal";
export { InformationalModal } from "./InformationalModal";
export * from "./Modal";
export { ModalFrame } from "./ModalFrame";
