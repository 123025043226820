import React, { PropsWithChildren } from "react";
import { gtmHeadTag } from "@leafly-com/web-utils";
import Script from "next/script";

import { SnackBarProvider } from "context/SnackBarContext";

import AgeGate from "components/AgeGate";
import { ModalFrame } from "components/botanic/Modal/ModalFrame";
import { CookiePopup } from "components/CookiePopup";
import Footer from "components/Footer";
import { GooglePublisherTagScript } from "components/GooglePublisherTagScript";
import { Header } from "components/Header";
import { PageTransitionProgressBar } from "components/PageTransitionProgressBar";
import { Toaster } from "components/Toaster";
import { WeedfettiCannon } from "components/Weedfetti";

import { AppSideEffects } from "./AppSideEffects";
import LayoutVisibility from "./LayoutVisibility";

/**
 * This Layout component reflects the layout that can be shared between the
 * App Router and Pages Router pages. Anything added to this layout _must_ be
 * compatible with both patterns.
 */
const Layout: React.FC<
  PropsWithChildren<{
    disableFixedHeader?: boolean;
    hideFooter?: boolean;
    hideHeader?: boolean;
    isAppBannerCookieDismissed?: boolean;
    showMinimalHeader?: boolean;
  }>
> = ({
  children,
  disableFixedHeader,
  hideFooter,
  hideHeader,
  isAppBannerCookieDismissed,
  showMinimalHeader,
}) => (
  <ModalFrame>
    <Script
      async
      id="google-tag-manager"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{ __html: gtmHeadTag }}
    />
    <GooglePublisherTagScript />
    <Toaster />

    <AgeGate />
    <AppSideEffects />
    <CookiePopup />

    <SnackBarProvider>
      <LayoutVisibility hidden={hideHeader} param="hide_header">
        <Header
          disableFixedPosition={disableFixedHeader}
          isAppBannerCookieDismissed={isAppBannerCookieDismissed}
          minimal={showMinimalHeader}
        />
      </LayoutVisibility>

      <main>{children}</main>

      <LayoutVisibility hidden={hideFooter} param="hide_footer">
        <Footer />
      </LayoutVisibility>
    </SnackBarProvider>

    <WeedfettiCannon />
    <PageTransitionProgressBar />
  </ModalFrame>
);

export default Layout;
