import React from "react";

import { Modal, ModalProps } from "./Modal";

type ActionRequiredModalProps = Omit<ModalProps, "title"> &
  Required<Pick<ModalProps, "aria-labelledby">>;

/**
 * ActionRequiredModal renders a Modal that is not dismissible.
 *
 * For general modal usage, see the sibling index module's documentation.
 */
export const ActionRequiredModal: React.FC<ActionRequiredModalProps> = ({
  ...props
}) => {
  return <Modal {...props} dismissible={false} disableSwipeToClose={true} />;
};
