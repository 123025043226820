"use client";

import React, { useEffect, useState } from "react";
import { addHours, addYears, isAfter } from "date-fns";
import dynamic from "next/dynamic";
import { usePathname, useSearchParams } from "next/navigation";

import useDomainCountryCode from "hooks/useDomainCountryCode";
import { availableStorage } from "utils/storage";
import { getUserAgent, userAgentIsGoogleBot } from "utils/userAgent";

const UsAgeGateForm = dynamic(() => import("./UsAgeGateForm"));
const CanadianAgeGateForm = dynamic(() => import("./CanadianAgeGateForm"));

enum StorageKeys {
  country = "acceptedTermsOfUseCountry",
  expiryDate = "acceptedTermsExpiryDate",
  firstVisitDate = "firstVisitDate",
  isAgeVerified = "isAgeVerified",
  version = "acceptedTermsOfUseVersion",
}

const TOU_VERSIONS: Record<string, number> = {
  CA: 3,
  US: 3,
};

const parseStorage = (storage: Storage) => ({
  country: storage.getItem(StorageKeys.country),
  expiryDate: storage.getItem(StorageKeys.expiryDate),
  version: storage.getItem(StorageKeys.version),
});

const checkTermsAcceptance = (
  countryCode: string,
  storage: ReturnType<typeof parseStorage>,
) => {
  if (!storage.country || !storage.expiryDate || !storage.version) {
    return false;
  }

  const touVersion = TOU_VERSIONS[countryCode.toUpperCase()];
  const versionsMatch = touVersion === parseInt(storage.version, 10);
  const countriesMatch =
    countryCode.toLowerCase() === storage.country.toLowerCase();
  const expired = isAfter(new Date(), new Date(storage.expiryDate));

  return versionsMatch && !expired && countriesMatch;
};

const AgeGate: React.FC = () => {
  const domainCountryCode = useDomainCountryCode();
  const query = useSearchParams();
  const pathname = usePathname() || "";

  const [showModal, setShowModal] = useState(false);
  const [rememberUser, setRememberUser] = useState(true);

  const confirm = () => {
    const storage = availableStorage();

    if (storage) {
      const expiryAmount = rememberUser
        ? addYears(new Date(), 1)
        : addHours(new Date(), 1);

      const storageItems = {
        [StorageKeys.country]: domainCountryCode.toLowerCase(),
        [StorageKeys.version]:
          TOU_VERSIONS[domainCountryCode.toUpperCase()] + "",
        [StorageKeys.expiryDate]: expiryAmount.toUTCString(),
        [StorageKeys.isAgeVerified]: "true",
        [StorageKeys.firstVisitDate]: new Date().toISOString(),
      };

      Object.keys(storageItems).forEach((key) =>
        storage.setItem(key, storageItems[key as StorageKeys]),
      );
    }

    setShowModal(false);
  };

  useEffect(() => {
    const storage = availableStorage();
    const isInfoPage = new RegExp(
      "^/info/(privacy-policy|terms-of-use|commercial-terms-of-use|jobs|contact|about)/?$",
    ).test(pathname);

    const enableAgeGate =
      (domainCountryCode !== "US" ||
        new RegExp("^(/dispensary-info)/*").test(pathname)) &&
      !query?.has("disable_age_gate") &&
      !isInfoPage;

    const termsHaveBeenAccepted =
      storage && checkTermsAcceptance(domainCountryCode, parseStorage(storage));
    const isGoogleBot = userAgentIsGoogleBot(getUserAgent());

    if (enableAgeGate && !termsHaveBeenAccepted && !isGoogleBot) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [domainCountryCode, pathname]);

  return (
    <>
      {showModal && domainCountryCode !== "CA" && (
        <UsAgeGateForm
          confirm={confirm}
          rememberUser={rememberUser}
          setRememberUser={setRememberUser}
        />
      )}
      {showModal && domainCountryCode === "CA" && (
        <CanadianAgeGateForm
          confirm={confirm}
          rememberUser={rememberUser}
          setRememberUser={setRememberUser}
        />
      )}
    </>
  );
};

export default AgeGate;
